<template>
  <v-main>
    <!-- Login card -->
    <div data-unit="card-text">
      <v-card-text>
        <p class="text-h3 mb-0 font-weight-medium cyan--text text--darken-2">
          {{ isLogin ? "¡Ingresa!" : "¡Regístrate!" }}
        </p>
        <p class="text-body-1 mb-2">
          {{ isLogin ? "Bienvenido de vuelta" : "Completa tus datos" }}
        </p>
      </v-card-text>
      <!-- Log-in form -->
      <v-card-text>
        <v-form>
          <v-text-field
            color="cyan darken-2"
            label="Correo electrónico"
            prepend-icon="mdi-account"
            v-model="credentials.email"
          />
          <v-text-field
            color="cyan darken-2"
            :type="showPassword ? 'text' : 'password'"
            label="Contraseña"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            v-model="credentials.password"
          />
        </v-form>
        <a
          class="cyan--text text--darken-2"
          @click.prevent="showConfirmation = true"
          :style="{
            visibility:
              isLogin && credentials.email !== '' ? 'visible' : 'hidden',
          }"
        >
        </a>
      </v-card-text>
      <!-- Send form -->
      <v-card-actions>
        <v-btn
          data-unit="authBtn"
          depressed
          rounded
          small
          dark
          class="mx-auto mt-2 px-3"
          color="cyan darken-2"
          @click="doAuth"
          >{{ isLogin ? "Iniciar sesión" : "Crear cuenta" }}
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <p>
          {{ isLogin ? "¿Eres nuevo?" : "¿Ya tienes cuenta?" }}
          <a
            class="font-weight-bold cyan--text text--darken-2"
            @click="updateNewUser"
            >{{ isLogin ? "Regístrate aquí." : "Ingresa aquí." }}</a
          >
        </p>
      </v-card-text>
    </div>
    <user-response
      v-if="showConfirmation"
      :email="credentials.email"
      @agree="doReset"
      @disagree="showConfirmation = false"
      >¿Deseas reiniciar tu contraseña?</user-response
    >
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
import UserResponse from "./UserResponse";
export default {
  name: "AuthForm",
  components: {
    UserResponse,
  },
  props: {
    isLogin: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    // Form
    credentials: {
      email: "",
      password: "",
    },
    showPassword: false,
    showConfirmation: false,
  }),
  methods: {
    updateNewUser() {
      this.$emit("change-form");
    },
    ...mapActions(["logIn", "signUp", "updateAlert", "resetPassword"]),
    doAuth() {
      console.log("fuak------------------------------------------------");
      const credentials = {
        email: this.credentials.email,
        password: this.credentials.password,
      };
      if (this.isLogin) {
        this.logIn(credentials);
      } else {
        this.signUp(credentials);
      }
    },
    doReset() {
      this.resetPassword(this.credentials.email);
      this.showConfirmation = false;
    },
  },
};
</script>
