<template>
  <v-main>
    <v-row no-gutters>
      <!-- Form on large devices -->
      <v-col cols="12" md="4" class="d-none d-md-block align-center">
        <v-img
          src="../assets/img/logoCircle.png"
          class="mx-auto mt-15"
          max-width="200"
        ></v-img>
        <v-card
          flat
          color="transparent"
          max-width="350"
          class="text-center mx-auto pa-2 mb-10"
        >
          <auth-form
            :is-login="isLogin"
            @change-form="toggleNewUser"
          ></auth-form>
        </v-card>
      </v-col>
      <!-- Form on small devices -->
      <v-col cols="12" md="8" class="pa-0">
        <!-- Image -->
        <v-img
          position="center top"
          height="100vh"
          class="pt-5 pb-10 align-center"
          src="https://firebasestorage.googleapis.com/v0/b/legionescenica.appspot.com/o/register%2Fingresa.jpg?alt=media&token=a4198bbe-8ebf-46e3-b65a-6a958b4db2e4"
        >
          <v-card
            flat
            max-width="320"
            class="d-md-none text-center rounded-xl mx-auto pa-2"
          >
            <auth-form
              :is-login="isLogin"
              @change-form="toggleNewUser"
            ></auth-form>

            <p class="text-caption">Soporte: <a href="mailto:contacto@audiodramapp.cl" class="font-weight-bold cyan--text text--darken-2 text-decoration-none">contacto@audiodramapp.cl</a></p>
          </v-card>
        </v-img>
      </v-col>
    </v-row>
    <!-- Photograph credits -->
    <v-footer padless absolute color="transparent">
      <v-row no-gutters class="text-caption px-7 mt-5">
        <v-col class="hidden-sm-and-down" cols="12" sm="6">
          <p>Soporte: <a href="mailto:contacto@audiodramapp.cl" class="font-weight-bold cyan--text text--darken-2 text-decoration-none">contacto@audiodramapp.cl</a></p>
        </v-col>
        <v-col class="text-center text-md-right white--text" cols="12" md="6">
          <p class="font-weight-bold">
            <v-icon small class="mr-2" color="white">mdi-camera</v-icon>
            Pablo Montt |
            <a class="text-decoration-none white--text" href="https://www.pablomontt.com/" target="_blank">
            pablomontt.com
            </a>
          </p>
        </v-col>
      </v-row>
    </v-footer>
  </v-main>
</template>

<script>
import AuthForm from "../components/AuthForm";

export default {
  name: "Login",
  data: () => ({
    newUser: true
  }),
  computed: {
    isLogin() {
      return !this.newUser;
    }
  },
  methods: {
    toggleNewUser() {
      this.newUser = !this.newUser;
    }
  },
  components: {
    AuthForm
  }
};
</script>