<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500px" overlay-color="black" overlay-opacity="0.8">
      <v-card class="px-3 py-5">
        <p class="text-center text-h6"
          >¿Deseas reiniciar tu contraseña?
        </p>
        <p class="text-center text-caption mb-0"
          >Se enviará un correo a <span class="font-weight-bold">{{email}}</span> para hacerlo.
        </p>
        <p class="text-center text-caption font-weight-bold">Revisa que esté correcto antes de aceptar.</p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white" class="px-3" small rounded outlined @click.prevent="$emit('disagree')">
            Volver
          </v-btn>
          <v-btn color="cyan darken-2" class="px-3" small rounded depressed dark @click.prevent="$emit('agree')">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
export default {
  name: "user-response",
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    email: String
  }
};
</script>